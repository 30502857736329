.footer {
    display: flex;
    flex-direction: row;
}
.footermobile {
    display: none;
}

@media (max-width: 950px) {
    .footer {
        display: none;
    }
    .footermobile {
        display: flex;
        flex-direction: column;
    }
}
